import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';


// @mui
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
import Chip from '@mui/material/Chip';

// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';

import api from '../utils/api';

// ----------------------------------------------------------------------
const ExpandableText = ({ text, maxLength }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  if (text.length <= maxLength) {
    return <span>{text}</span>;
  }

  return (
    <p>
      {isExpanded ? text : `${text.substring(0, maxLength)}... `}
      <Button onClick={handleToggle}>
        {isExpanded ? 'ver menos' : 'ver más...'}
      </Button>
    </p>
  );
};

const MAX_LENGTH = 50;

const TABLE_HEAD = [
  { id: 'id', label: 'ID', alignRight: false },
  { id: 'user_id', label: 'User ID', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'appellido1', label: 'Apellido1', alignRight: false },
  { id: 'appellido2', label: 'Apellido2', alignRight: false },
  { id: 'telefono', label: 'Telefono', alignRight: false },
  { id: 'correo', label: 'Correo', alignRight: false },
  { id: 'palabra', label: 'PalabraR', alignRight: false },
  { id: 'descripcion', label: 'Descripción', alignRight: false },
  { id: 'proceso', label: 'Proceso', alignRight: false },
  { id: 'nisa', label: 'NISA', alignRight: false },
  { id: 'verificacion', label: 'Verificacion', alignRight: false },
  { id: '', label: 'Acciones', alignRight: true },
];


// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [usersData, setUsersData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState('');

  const [selectedId, setSelectedId] = useState(null);

  const [isCvvModalOpen, setIsCvvModalOpen] = useState(false);
  const [cvv, setCvv] = useState('');


  const handleVerifyClick = async (id) => {
    setIsLoading(true);
    try {
      // Send a PATCH request to the server
      const response = await api.patch(`${process.env.REACT_APP_BACKEND_URL}/updateProceso/${id}`);
      console.log(response.data); // Log the response data

      // Update local state or UI if necessary
      // For instance, re-fetching data or updating a status message

    } catch (error) {
      console.error('Error updating record:', error);
      // Handle errors, maybe set an error message in state and display it
    } finally {
      setIsLoading(false); // Reset loading state
    }
  };


  useEffect(() => {
    const fetchUsersData = async () => {
      try {
        const response = await api.get(`${process.env.REACT_APP_BACKEND_URL}/obtenerTodosLosDatos`);
        setUsersData(response.data); // Asume que los datos están en response.data
        console.log(response.data)
      } catch (error) {
        console.error('Error al obtener los datos:', error);
      }
    };

    fetchUsersData();
  }, []);

  const handleOpenMenu = (event, userId) => {
    setOpen(event.currentTarget);
    setSelectedId(userId); // Guardar el ID seleccionado
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleOpenCvvModal = () => {
    setIsCvvModalOpen(true);
  };


  const handleDeleteClick = async (id) => {
    try {
      // Make a DELETE request to the server
      const response = await api.delete(`${process.env.REACT_APP_BACKEND_URL}/deleteDatosImpi/${id}`);
      console.log(response.data); // Log the response data

      // Optionally: Remove the deleted item from the local state to update the UI
      setUsersData(usersData.filter((item) => item.id !== id));

      // You might want to fetch the updated data list or handle UI changes here
    } catch (error) {
      console.error('Error deleting record:', error);
      // Handle errors, maybe set an error message in state and display it
    }
  };


  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(usersData, getComparator(order, orderBy), filterName);


  const isNotFound = !filteredUsers.length && !!filterName;

  const iniciarProceso = async (id, cvv) => {
    setIsLoading(true);
    setError('');

    const registro = usersData.find(registro => registro.id === id);

    if (!registro) {
      setError(`No se encontró el registro con el id ${id}.`);
      setIsLoading(false);
      return;
    }

    try {
      const startResponse = await api.post('/start', {}, { withCredentials: true });
      console.log('Proceso iniciado:', startResponse.data);

      if (startResponse.status === 200) {
        const tipoPayload = { tipo: 1 };
        const tipoResponse = await api.post('/tipo', tipoPayload, { withCredentials: true });
        console.log('Tipo enviado:', tipoResponse.data);

        const palabraPayload = {
          palabras: registro.palabra,
          reservable: registro.valorReservable,
          traduccion: registro.traduccion
        };
        const palabraResponse = await api.post('/palabra', palabraPayload, { withCredentials: true });
        console.log('Palabra enviada:', palabraResponse.data);

        if (palabraResponse.status === 200) {
          const clasePayload = {
            tipo: registro.nisa,
            descripcion: registro.descripcion
          };
          const claseResponse = await api.post('/clase', clasePayload, { withCredentials: true });
          console.log('Clase enviada:', claseResponse.data);

          if (claseResponse.status === 200) {
            const duenoPayload = {
              curp: registro.curp,
              nombres: registro.name,
              apellido1: registro.apellido1,
              apellido2: registro.apellido2,
              nacionalidad: "138", // Assuming the nationality ID for all users is "138" in your system
              telefono: registro.telefono,
              correo: registro.correo,
              calle: registro.calle,
              exterior: registro.exterior,
              interior: registro.interior,
              cp: registro.cp,
              col: registro.col,
              muni: registro.muni,
              estado: registro.estado,
              pais: "138" // Assuming the country ID for all users is "138" in your system
            };
            const duenoResponse = await api.post('/dueno', duenoPayload, { withCredentials: true });
            console.log('Datos del dueño enviados:', duenoResponse.data);
            if (duenoResponse.status === 200) {
              const marcadataPayload = {
                // Aquí podrías incluir otros datos necesarios para la petición
                cvv // Pasas el CVV recibido como argumento
              };

              const marcadataResponse = await api.post('/marcadata', marcadataPayload, { withCredentials: true });
              console.log('Datos de marca enviados:', marcadataResponse.data);

              // Handle the response from the /marcadata endpoint here
            }
          }
        }
      }
    } catch (error) {
      console.error('Error en el proceso:', error);
      setError('Ha ocurrido un error al iniciar el proceso.');
    }
    setIsLoading(false);
  };


  const handleRegisterClick = (userId) => {
    console.log("Registro seleccionado con ID:", userId);
    setSelectedId(userId); // Asegúrate de tener este estado definido para saber qué registro se está procesando
    handleOpenCvvModal(); // Abre el modal para introducir el CVV
  };


  return (
    <>
      <Helmet>
        <title> User | Minimal UI </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Registros de usuarios
          </Typography>
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />

                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    // eslint-disable-next-line camelcase
                    const { id, user_id, name, apellido1, apellido2, telefono, correo, palabra, descripcion, proceso, nisa, verificacion } = row;

                    return (
                      <TableRow hover key={id}>
                        <TableCell padding="checkbox">
                          <Checkbox onChange={(event) => handleClick(event, name)} />
                        </TableCell>

                        <TableCell>{id}</TableCell>
                        {/* eslint-disable-next-line camelcase */}
                        <TableCell>{user_id}</TableCell>
                        <TableCell>{name}</TableCell>
                        <TableCell>{apellido1}</TableCell>
                        <TableCell>{apellido2}</TableCell>
                        <TableCell>{telefono}</TableCell>
                        <TableCell>{correo}</TableCell>
                        <TableCell>{palabra}</TableCell>
                        <TableCell> <ExpandableText text={descripcion} maxLength={MAX_LENGTH} /></TableCell>
                        <TableCell>
                          {proceso ? (
                            <Chip label="Hecho" size="small" color="success" />
                          ) : (
                            <Chip label="Inactivo" size="small" color="error" />
                          )}
                        </TableCell>

                        <TableCell>{nisa}</TableCell>
                        <TableCell>
                          {verificacion ? (
                            <Chip label="Hecho" size="small" color="success" />
                          ) : (
                            <Chip label="Inactivo" size="small" color="error" />
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, id)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={12} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        {/* <MenuItem onClick={iniciarProceso} disabled={isLoading}> */}
        <MenuItem onClick={() => handleRegisterClick(selectedId)} disabled={isLoading}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          {isLoading ? 'Procesando...' : 'Registrar'}
        </MenuItem>

        <MenuItem onClick={() => handleVerifyClick(selectedId)} disabled={isLoading}>
          <Iconify icon={'eva:checkmark-fill'} sx={{ mr: 2 }} />
          Verificado
        </MenuItem>



        <MenuItem sx={{ color: 'error.main' }} onClick={() => handleDeleteClick(selectedId)}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
      <Dialog open={isCvvModalOpen} onClose={() => setIsCvvModalOpen(false)}>
        <DialogTitle>Introduzca el CVV</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="cvv"
            label="CVV"
            type="text"
            fullWidth
            variant="standard"
            value={cvv}
            onChange={(e) => setCvv(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsCvvModalOpen(false)}>Cancelar</Button>
          <Button onClick={() => {
            setIsCvvModalOpen(false);
            iniciarProceso(selectedId, cvv);
          }}>Iniciar</Button>

        </DialogActions>
      </Dialog>

    </>
  );
}
