// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------
// Función para crear íconos SVG con color
const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

// Obtiene la información del usuario desde localStorage
const user = JSON.parse(localStorage.getItem('user'));

// Comprueba si el usuario es administrador
const isAdmin = user && user.tipo === 1;

// // Configuración inicial de la navegación
// const navConfig = [
//   {
//     title: 'Verificación',
//     path: '/dashboard/app',
//     icon: icon('ic_analytics'),
//   },
//   {
//     title: 'Registro',
//     path: '/dashboard/products',
//     icon: icon('ic_cart'),
//   },
//   {
//     title: 'Perfil',
//     path: '/dashboard/blog',
//     icon: icon('ic_blog'),
//   },
//   // {
//   //   title: 'login',
//   //   path: '/login',
//   //   icon: icon('ic_lock'),
//   // },
//   // {
//   //   title: 'Not found',
//   //   path: '/404',
//   //   icon: icon('ic_disabled'),
//   // },
// ];

// // Si el usuario es administrador, añadir el ítem ADMIN al inicio del array de configuración
// if (isAdmin) {
//   navConfig.unshift({
//     title: 'Registros',
//     path: '/dashboard/user',
//     icon: icon('ic_user'),
//   });
//   navConfig.unshift({
//     title: 'Crear usuario',
//     path: '/dashboard/createuser',
//     icon: icon('ic_user'),
//   });
// }

// export default navConfig;


const getNavConfig = (user) => {
  const isAdmin = user && user.tipo === 1;

  const config = [
    {
      title: 'Verificación',
      path: '/dashboard/app',
      icon: icon('ic_analytics'),
    },
    {
      title: 'Registro',
      path: '/dashboard/products',
      icon: icon('ic_cart'),
    },
    {
      title: 'Perfil',
      path: '/dashboard/blog',
      icon: icon('ic_blog'),
    }
  ];

  if (isAdmin) {
    config.unshift({
      title: 'Crear usuario',
      path: '/dashboard/createuser',
      icon: icon('ic_user'),
    });
    config.unshift({
      title: 'Registros',
      path: '/dashboard/user',
      icon: icon('ic_user'),
    });
    config.unshift({
      title: 'Todos los usuarios',
      path: '/dashboard/allusers',
      icon: icon('ic_user'),
    });
    config.unshift({
      title: 'Agregar notificaciones',
      path: '/dashboard/addnot',
      icon: icon('ic_user'),
    });
  }

  return config;
};

export default getNavConfig;
