import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';



// @mui
import {
  Card, Box, TextField,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  CircularProgress,
  Modal
} from '@mui/material';
import Chip from '@mui/material/Chip';

// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';

import api from '../utils/api';

// ----------------------------------------------------------------------
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    position: 'absolute',
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    width: 400, // Puedes ajustar el ancho según necesites
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
}
function EditUserForm({ user, onSave, onCancel }) {

  const [editedUser, setEditedUser] = useState({
    id: "",
    nombre: "",
    telefono: "",
    correo: "",
    url: "",
    tipo: ""
  });
  // Nuevo estado para manejar el estado de carga
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = async () => {
    setIsSaving(true); // Inicia el icono de carga
    try {
      await onSave(editedUser);
    } catch (error) {
      console.error("Error al guardar: ", error);
    }
    setIsSaving(false); // Finaliza el icono de carga
  };

  useEffect(() => {
    console.log(user)
    if (user) {
      setEditedUser({
        id: user.user_id || "",
        name: user.name || "", // Cambiado de nombre a name
        telefono: user.telefono || "",
        correo: user.correo || "",
        url: user.url || "",
        tipo: user.tipo || ""
      });
    }
  }, [user]);



  const tipos = [
    { label: "Admin", value: 1 },
    { label: "Registro Marca", value: 0 },
    // ... otros tipos
  ];


  // Manejador para los cambios en los inputs del formulario
  const handleChange = (event) => {
    const { name, value } = event.target;
    setEditedUser(prev => ({ ...prev, [name]: value })); // [name] es dinámico, coincidirá con "name", "telefono", etc.
  };



  return (
    <Box sx={getModalStyle()}>
      <Typography variant="h6" gutterBottom>
        Editar Usuario
      </Typography>
      <TextField
        name="name"
        label="Nombre"
        value={editedUser.name}
        onChange={handleChange}
        margin="normal"
        fullWidth
      />

      <TextField
        name="telefono"
        label="Telefono"
        value={editedUser.telefono}
        onChange={handleChange}
        margin="normal"
        fullWidth
      />
      <TextField
        name="correo"
        label="Correo"
        value={editedUser.correo}
        onChange={handleChange}
        margin="normal"
        fullWidth
      />
      <TextField
        name="url"
        label="Imagen"
        value={editedUser.url}
        onChange={handleChange}
        margin="normal"
        fullWidth
      />
      <TextField
        select
        label="Tipo"
        name="tipo"
        value={editedUser.tipo}
        onChange={handleChange}
        fullWidth
        margin="normal"
      >
        {tipos.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      {/* Agrega aquí más campos si son necesarios */}
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <Button color="inherit" onClick={onCancel}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          sx={{ ml: 1 }}
          disabled={isSaving} // Deshabilita el botón mientras se guarda
        >
          {isSaving ? <CircularProgress size={24} /> : "Guardar"}
        </Button>
      </Box>
    </Box>
  );
}
const TABLE_HEAD = [
  { id: 'user_id', label: 'User ID', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'telefono', label: 'Telefono', alignRight: false },
  { id: 'correo', label: 'Correo', alignRight: false },
  { id: 'url', label: 'Imagen', alignRight: false },
  { id: 'tipo', label: 'Tipo', alignRight: false },
  { id: '', label: 'Acciones', alignRight: true },
];


// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [usersData, setUsersData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState('');

  const [selectedId, setSelectedId] = useState(null);

  const [editingUserId, setEditingUserId] = useState(null);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const handleEditClick = (userId) => {
    setEditingUserId(userId);
    setIsEditModalOpen(true);
  };

  const handleSaveEdit = async (editedUser) => {
    setIsEditModalOpen(false);

    // Aquí crearías el cuerpo de la solicitud basado en los datos actualizados


    try {
      const updatedData = {
        nombre: editedUser.name, // Asumiendo que tu API espera 'nombre' en lugar de 'name'
        cell: editedUser.telefono,
        url: editedUser.url,
        tipo: editedUser.tipo,
      };
      // Utiliza la id del usuario para hacer la solicitud PATCH
      const response = await api.patch(`${process.env.REACT_APP_BACKEND_URL}/user/${editedUser.id}`, updatedData);

      if (response.status === 200) {
        fetchUsersData();
        setUsersData(usersData.map(user => user.id === editedUser.id ? { ...user, ...updatedData } : user));
      } else {
        console.error('Error al actualizar el usuario:', response);
      }
    } catch (error) {
      console.error('Error al actualizar el usuario:', error);
    }
  };


  const handleCancelEdit = () => {
    setIsEditModalOpen(false);
  };

  const handleDeleteUser = async (userId) => {
    try {
      await api.delete(`${process.env.REACT_APP_BACKEND_URL}/user/${userId}`);
      // Actualizar la lista de usuarios después de la eliminación
      fetchUsersData();
    } catch (error) {
      console.error('Error al eliminar el usuario:', error);
    }
  };


  const fetchUsersData = async () => {
    try {
      const response = await api.get(`${process.env.REACT_APP_BACKEND_URL}/users`);
      const mappedData = response.data.map(user => ({
        id: user.id,
        user_id: user.id,
        name: user.nombre,
        telefono: user.cell,
        correo: user.correo,
        url: user.url,
        tipo: user.tipo
        // Asegúrate de incluir aquí todos los campos que necesites
      }));
      setUsersData(mappedData);
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  };

  useEffect(() => {
    fetchUsersData();
  }, []);


  const handleOpenMenu = (event, userId) => {
    setOpen(event.currentTarget);
    setSelectedId(userId); // Guardar el ID seleccionado
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleDeleteClick = async (id) => {
    await handleDeleteUser(id);
  };



  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(usersData, getComparator(order, orderBy), filterName);


  const isNotFound = !filteredUsers.length && !!filterName;




  const handleRegisterClick = (userId) => {
    console.log("Registro seleccionado con ID:", userId);
    // Aquí puedes agregar la lógica para manejar el registro seleccionado
    // iniciarProceso(userId);
  };


  return (
    <>
      <Helmet>
        <title> User | Minimal UI </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Todos los usuarios
          </Typography>
        </Stack>
        {isEditModalOpen && (
          <Modal
            open={isEditModalOpen}
            onClose={handleCancelEdit}
            aria-labelledby="edit-user-modal-title"
            aria-describedby="edit-user-modal-description"
          >
            <EditUserForm
              user={usersData.find(user => user.id === editingUserId)}
              onSave={handleSaveEdit}
              onCancel={handleCancelEdit}
            />
          </Modal>
        )}

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />

                <TableBody>
                  {usersData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    // eslint-disable-next-line camelcase
                    const { id, user_id, name, apellido1, apellido2, telefono, correo, palabra, descripcion, proceso, nisa, verificacion } = row;

                    return (
                      <TableRow hover key={id}>
                        <TableCell padding="checkbox">
                          <Checkbox onChange={(event) => handleClick(event, name)} />
                        </TableCell>

                        {/* <TableCell>{id}</TableCell> */}
                        {/* eslint-disable-next-line camelcase */}
                        <TableCell>{row.user_id}</TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.telefono}</TableCell>
                        <TableCell>{row.correo}</TableCell>
                        <TableCell>{row.url}</TableCell>
                        <TableCell>{row.tipo}</TableCell>
                        <IconButton onClick={() => handleEditClick(row.id)}>
                          <Iconify icon={'eva:edit-outline'} />
                        </IconButton>
                        <IconButton onClick={() => handleDeleteClick(row.id)}>
                          <Iconify icon={'eva:trash-2-outline'} />
                        </IconButton>

                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={12} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        {/* <MenuItem onClick={iniciarProceso} disabled={isLoading}> */}
        <MenuItem onClick={() => handleRegisterClick(selectedId)} disabled={isLoading}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          {isLoading ? 'Procesando...' : 'Registrar'}
        </MenuItem>




        <MenuItem sx={{ color: 'error.main' }} onClick={() => handleDeleteClick(selectedId)}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>



      </Popover>
    </>
  );
}
