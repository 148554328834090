import { Navigate, useRoutes } from 'react-router-dom';

// Importaciones relacionadas con la autenticación
import { AuthProvider, useAuth } from './utils/AuthContext';
import ProtectedRoute from './utils/ProtectedRoute';

// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';

// pages
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import CreateUserPage from './pages/CreateUser';
import SignupPage from './pages/SignUp';
import AllUsers from './pages/AllUsers';
import AddNot from './pages/AddNotifications';

export default function Router() {
  const { isAuthenticated } = useAuth();

   // Obtiene la información del usuario desde localStorage
   const user = JSON.parse(localStorage.getItem('user'));

   // Comprueba si el usuario es administrador
   const isAdmin = user && user.tipo === 1;
 

  const routes = useRoutes([
    // Agrega esta ruta específica para la raíz
    {
      path: '/',
      element: isAuthenticated ? <Navigate to="/dashboard/app" /> : <Navigate to="/login" />,
    },
    {
      path: '/dashboard',
      element: isAuthenticated ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { path: 'app', element: <DashboardAppPage /> },
        { 
          path: 'user', 
          element: isAdmin ? <UserPage /> : <Navigate to="/dashboard/app" />
        },
        { 
          path: 'createuser', 
          element: isAdmin ? <CreateUserPage /> : <Navigate to="/dashboard/app" />
        },
        { 
          path: 'allusers', 
          element: isAdmin ? <AllUsers /> : <Navigate to="/dashboard/app" />
        },
        { 
          path: 'addnot', 
          element: isAdmin ? <AddNot /> : <Navigate to="/dashboard/app" />
        },
        { path: 'products', element: <ProductsPage /> },
        { path: 'blog', element: <BlogPage /> },
      ],
    },
    {
      path: 'login',
      element: !isAuthenticated ? <LoginPage /> : <Navigate to="/dashboard/app" />,
    },
    {
      path: 'signup',
      element: !isAuthenticated ? <SignupPage /> : <Navigate to="/dashboard/app" />,
    },
    {
      element: isAuthenticated ? <SimpleLayout /> : <Navigate to="/login" />,
      children: [
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/login" />,
    },
  ]);

  return routes;
}
