import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Alert from '@material-ui/lab/Alert';

import { FaPaperPlane } from 'react-icons/fa';
import styled, { createGlobalStyle } from 'styled-components';
import { Modal } from '../components/modal/modal';
import Tooltip from '../components/Tooltip';
import api from '../utils/api';
import {
  AppContainer, ChatBox, InputBox,
  MessageInput, MessageBubble, GlobalStyle, ServerStyledMessage,
  AvatarContainer, ServerMessageBubble, StyledMessage, EditInfoButton, StartButton,
  SendButton, ModalContent, ModalHeader, ModalInputContainer, ModalInput, StyledTooltip, ModalTextarea,
  ModalActions, ModalButton, SecondModalContent, SecondModalHeader, LeftColumn, SecondModalInput, RightColumn
} from './AppStyles';



function App() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [conversationStarted, setConversationStarted] = useState(false);
  const messagesEndRef = useRef(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalOpen2, setModalOpen2] = useState(false);
  const [isModalOpen3, setModalOpen3] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showConfirmationAlert, setShowConfirmationAlert] = useState(false);


  const [curp, setCurp] = useState('');
  const [name, setName] = useState('');
  const [apellido1, setApellido1] = useState('');
  const [apellido2, setApellido2] = useState('');
  const [nacionalidad, setNacionalidad] = useState('');
  const [telefono, setTelefono] = useState('');
  const [correo, setCorreo] = useState('');
  const [calle, setCalle] = useState('');
  const [exterior, setExterior] = useState('');
  const [interior, setInterior] = useState('');
  const [cp, setCP] = useState('');
  const [col, setCol] = useState('');
  const [muni, setMuni] = useState('');
  const [estado, setEstado] = useState('');
  const [pais, setPais] = useState('');
  const [idUser, setId] = useState('');
  const [email, setEmail] = useState('');



  const [palabra, setPalabra] = useState('');
  const [valorReservable, setValorReservable] = useState('');
  const [traduccion, setTraduccion] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [numRr, setNumRr] = useState('');
  const [nisa, setNiza] = useState('');


  const [isLoading, setIsLoading] = useState(false);
  const [showOwnerInfoButton, setShowOwnerInfoButton] = useState(false);
  // Nuevo estado para almacenar toda la información del usuario y de la marca
  const [allInfo, setAllInfo] = useState(null);
  const [allInfo2, setAllInfo2] = useState(null);
  const [showConfirmationButton, setShowConfirmationButton] = useState(false);
  const baseURL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('user'));
    if (userData && userData.url) {
      setId(userData.id);
      setEmail(userData.correo);
    }
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchNumRegistros = async () => {
      const userData = JSON.parse(localStorage.getItem('user'));
      if (userData && userData.correo) {
        try {
          const response = await api.get(`${process.env.REACT_APP_BACKEND_URL}/numRegistros/correo/${userData.correo}`);
          const { numR } = response.data;
          setNumRr(numR);
          if (numR < 1) {
            alert("Ya has hecho los registros por los que pagaste");
            // Redirigir después de cerrar la alerta
            navigate('/dashboard/blog');
          } else {
            alert(`Te quedan ${numR} número de intentos para registrar una marca`);
          }
        } catch (error) {
          console.error("Error al obtener los registros permitidos:", error);
        }
      }
    };
  
    fetchNumRegistros();
  }, [navigate]);
  
  

  const floatingAlertStyle = {
    position: 'fixed', // Posición fija respecto al viewport
    bottom: '20px', // 20px desde el fondo del viewport para el primer alerta
    right: '20px', // 20px desde el lado derecho del viewport
    zIndex: 1000, // Asegurarse de que flote sobre otros elementos
    width: 'auto', // El ancho puede ser auto o un valor específico
  };

  const floatingConfirmationStyle = {
    position: 'fixed',
    bottom: '70px', // Espacio adicional para no superponerse con el primer alerta
    right: '20px',
    zIndex: 1000,
    width: 'auto',
  };


  // Para la información del usuario
  const formatAllInfo = (info) => {
    return (
      `<ul>
          <li><b>CURP:</b> ${info.curp}</li>
          <li><b>Nombre:</b> ${info.name}</li>
          <li><b>Apellido 1:</b> ${info.apellido1}</li>
          <li><b>Apellido 2:</b> ${info.apellido2}</li>
          <li><b>Nacionalidad:</b> ${info.nacionalidad}</li>
          <li><b>Teléfono:</b> ${info.telefono}</li>
          <li><b>Correo:</b> ${info.correo}</li>
          <li><b>Calle:</b> ${info.calle}</li>
          <li><b>Número Exterior:</b> ${info.exterior}</li>
          <li><b>Número Interior:</b> ${info.interior}</li>
          <li><b>Código Postal:</b> ${info.cp}</li>
          <li><b>Colonia:</b> ${info.col}</li>
          <li><b>Municipio:</b> ${info.muni}</li>
          <li><b>Estado:</b> ${info.estado}</li>
      </ul>`
    );
  };

  // Para la información de la marca
  const formatAllInfoMarca = (info) => {
    return (
      `<ul>
          <li><b>Palabra:</b> ${info.palabra}</li>
          <li><b>Valor Reservable:</b> ${info.valorReservable}</li>
          <li><b>Traducción:</b> ${info.traduccion}</li>
          <li><b>Descripción:</b> ${info.descripcion}</li>
      </ul>`
    );
  };


  const showInfo = () => {
    setAllInfo({
      curp, name, apellido1, apellido2, nacionalidad,
      telefono, correo, calle, exterior, interior, cp,
      col, muni, estado, pais
    });
    setAllInfo2({
      palabra, valorReservable,
      traduccion, descripcion, nisa
    });

    // Añadir un mensaje de confirmación al chat del servidor

    const infoText = formatAllInfo(allInfo);
    const infoText2 = formatAllInfoMarca(allInfo2);
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: `La información se guardó correctamente.\n${infoText}`, sender: 'server' },
      { text: `Info marca.\n${infoText2}`, sender: 'server' }
    ]);
    closeModal2();
  }

  const handleGuardar2 = () => {
    showInfo();  // Enable viewing all information
    setShowConfirmationButton(true); // Show the confirmation button
    closeModal2();
  };

  const enviarDatosAlServidor = async () => {
    const nuevoNumR = numRr - 1;
    setNumRr(nuevoNumR);
    
    const payload = {
      user_id: idUser, // Asegúrate de obtener el user_id correcto
      ...allInfo,
      ...allInfo2,
      proceso: false // Campo de proceso predeterminado en false
    };

    try {
      const response = await api.post(`${process.env.REACT_APP_BACKEND_URL}/insertarDatos`, payload);
      console.log('Respuesta del servidor:', response.data);
      try {
        await api.put(`${process.env.REACT_APP_BACKEND_URL}/numRegistros/correo/${email}`, {
          numR: nuevoNumR
        });
        console.log('Número de registros actualizado correctamente');
      } catch (error) {
        console.error('Error al actualizar el número de registros:', error);
      }
  
      if (response.status === 201) { // Asegúrate de ajustar esta condición según lo que tu servidor devuelva
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: "Registro de marca en proceso, te llegará un correo para la verificación de tu registro", sender: 'server' }
        ]);
        console.log('Payload enviado:', payload);
        // Preparar el HTML del correo electrónico
        const emailHtml = `
        <!DOCTYPE html>
        <html lang="es">
        
        <head>
            <meta charset="UTF-8">
            <title>Confirmación de Reserva</title>
            <style>
                body {
                    font-family: Arial, sans-serif;
                    margin: 0;
                    padding: 0;
                }
        
                .email-container {
                    max-width: 600px;
                    margin: auto;
                    background-color: #ffffff;
                    padding: 20px;
                }
        
                .header-logo {
                    text-align: center;
                    margin-bottom: 20px;
                }
        
                .content-block {
                    text-align: center;
                }
        
                .reservation-info {
                    background-color: #f9f9f9;
                    padding: 15px;
                    border-radius: 8px;
                }
        
                .reservation-info h2 {
                    color: #FFFFFF;
                }
        
                .restaurant-name {
                    font-weight: bold;
                }
        
                .confirmation-number {
                    margin: 5px 0;
                }
        
                .button {
                    display: inline-block;
                    padding: 10px 15px;
                    margin: 5px;
                    background-color: #FFFFFF;
                    color: white;
                    border-radius: 5px;
                    text-decoration: none;
                }
        
                .footer {
                    text-align: center;
                    margin-top: 20px;
                }
            </style>
        </head>
        
        <body>
            <div class="email-container">
                <div class="header-logo"><img
                        src="https://businessadvocacy.com.mx/wp-content/uploads/2022/09/cropped-logo_business-removebg-preview-1-1.png"
                        alt="businessadvocacy logo" width="100" /></div>
                <div class="content-block">
                    <h2>Se ha realizado el registro de tu marca dentro de nuestra plataforma con IA</h2><img
                        src="https://www.lisdatasolutions.com/wp-content/uploads/2022/07/artificial-neural-network-3501528_1920.png"
                        alt="Imagen del Restaurante" style="max-width: 100px; border-radius: 50%;" />
                    <div class="reservation-info">
                        <h3 class="restaurant-name">Nombre de Marca:</h3>
                        <p>${palabra}</p>
                        <p class="confirmation-number">Descripción: ${descripcion}</p>
                        <p class="confirmation-number">Clasificación NIZA: ${nisa}</p>
                        <a href="http://localhost:8000/verificar?id=${idUser}" class="button">Todo es correcto</a>
                        <a
                    href="https://api.whatsapp.com/send/?phone=5219514199661&text=Hola%2C+necesito+m%C3%A1s+informaci%C3%B3n+Businessverso+https%3A%2F%2Fbusinessverso.com.mx&type=phone_number&app_absent=0"
                    class="button">Obtener ayuda</a>
                    </div>
                </body>
                </html>
              `;

        // Enviar el correo electrónico
        try {
          const emailResponse = await api.post(`${process.env.REACT_APP_BACKEND_URL}/send-email`, {
            to: correo,
            subject: "Confirmación de datos de marca",
            html: emailHtml
          });
          setShowAlert(true);
          setShowConfirmationAlert(true);

          // Puedes usar un temporizador para ocultar la alerta después de un tiempo
          setTimeout(() => {
            setShowAlert(false);
            setShowConfirmationAlert(false);
          }, 10000); // cierra las alertas después de 5 segundos, por ejemplo
          if (emailResponse.status === 200) {
            console.log('Correo enviado correctamente');
            // Mostrar alertas después de enviar los datos

          } else {
            console.error('Error al enviar correo', emailResponse);
          }
        } catch (error) {
          console.error('Error al enviar correo', error);
        }
      } else {
        // console.log(response.status)
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: "Registro de marca en proceso, te llegará un correo para la verificación de tu registro", sender: 'server' }
        ]);
        console.log('Payload enviado:', payload);

      }

    } catch (error) {
      console.error('Error al enviar los datos:', error);
      // Manejo del error, por ejemplo, mostrar un mensaje de error
    }
  };


  const handleSend = async () => {
    if (input.trim() === '') return;

    setMessages((prevMessages) => [...prevMessages, { text: input, sender: 'user' }]);
    setInput('');

    // Aquí podrías añadir lógica para obtener una respuesta del servidor y añadirlo a "messages"
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  const closeModal2 = () => {
    setModalOpen2(false);
    setModalOpen3(true);
  };

  const openModal = () => {
    setModalOpen(true);
  };
  const startConversation = () => {
    setConversationStarted(true);
    setModalOpen(true); // Abre el modal
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const sendToTexto = async () => {
    setIsLoading(true);
    const payload = {
      Nombre: palabra,
      Reservadas: valorReservable,
      Descripción: descripcion,
    };

    try {
      const response = await api.post(`${process.env.REACT_APP_BACKEND_URL}/texto`, payload);
      console.log('Successfully sent to /texto:', response.data);
      // Verifica la respuesta del servidor
      if (response.data.response === "200") {
        setMessages((prevMessages) => [...prevMessages, { text: "Tus respuestas han sido procesadas con éxito", sender: 'server' }]);
      } else {
        setMessages((prevMessages) => [...prevMessages, { text: response.data.response, sender: 'server' }]);
      }
      try {
        const payloadNiza = {
          Descripcion: descripcion // Asumiendo que quieres enviar la misma descripción
        };

        const responseNiza = await api.post(`${process.env.REACT_APP_BACKEND_URL}/verificarNiza`, payloadNiza);
        console.log('Respuesta de verificarNiza:', responseNiza.data.response);
        setNiza(responseNiza.data.response);
      } catch (errorNiza) {
        console.error('Error al enviar a /verificarNiza:', errorNiza);
        setMessages((prevMessages) => [...prevMessages, { text: "Hubo un error al procesar tu solicitud.", sender: 'server' }]);
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error sending to /texto:', error);
      setMessages((prevMessages) => [...prevMessages, { text: "Hubo un error al procesar tu solicitud.", sender: 'server' }]);
      setIsLoading(false);
    }
  };
  const openSecondModal = () => {
    setModalOpen2(true);
  };

  const handleGuardar = () => {
    sendToTexto();
    closeModal();
    setShowOwnerInfoButton(true);
  };
  const iniciarRegistro = async () => {

  };


  return (
    <AppContainer>
      <ChatBox>
        {/* Si la conversación ha empezado, muestra los mensajes */}
        {showAlert && (
          <Alert
            variant="filled"
            severity="info"
            style={{
              ...floatingAlertStyle,
              backgroundColor: 'blue',
              color: 'white'
            }}>
            Verifica la información de tu marca en tu correo ingresado en la información del dueño de marca
          </Alert>
        )}

        {showConfirmationAlert && (
          <Alert
            variant="filled"
            severity="success"
            style={{
              ...floatingConfirmationStyle,
              backgroundColor: 'green',
              color: 'white'
            }}>
            Información guardada correctamente
          </Alert>
        )}
        <GlobalStyle />
        {isLoading && (
          <ServerStyledMessage sender="server">
            <AvatarContainer>
              <Avatar>{/* Aquí puedes poner la imagen o el inicial del servidor */}</Avatar>
            </AvatarContainer>
            <ServerMessageBubble sender="server">
              <p>Cargando...</p>
            </ServerMessageBubble>
          </ServerStyledMessage>
        )}

        {messages.map((message, index) => (
          <StyledMessage key={index} sender={message.sender}>
            {message.sender === 'server' ? (
              <>
                <AvatarContainer>
                  <Avatar src='https://www.elcorreo.com/xlsemanal/wp-content/uploads/sites/5/2023/04/alan-turing-inventor-informatica-espia-codigo-enigma-segunda-guerra-mundial.jpg'>{/* Avatar del servidor */}</Avatar>
                </AvatarContainer>
                {/* Asegúrate de validar o limpiar este HTML si proviene de una fuente externa para evitar ataques XSS */}
                <MessageBubble sender="server" dangerouslySetInnerHTML={{ __html: message.text }} />
              </>
            ) : (
              <>
                <MessageBubble sender="user">{message.text}</MessageBubble>
                <AvatarContainer>
                  <Avatar>{/* Avatar del usuario */}</Avatar>
                </AvatarContainer>
              </>
            )}
          </StyledMessage>
        ))}

        {conversationStarted ? (
          <>
            <EditInfoButton onClick={openModal}>Editar información de marca</EditInfoButton>
            <br />

            {/* Botón para agregar información del dueño, sólo visible si showOwnerInfoButton es true */}
            <br />
            {showOwnerInfoButton ? (
              <StartButton onClick={openSecondModal} style={{ backgroundColor: isModalOpen3 ? '#ff9900' : 'green' }}>
                {isModalOpen3 ? 'Editar información del dueño' : 'Agregar información del dueño de la marca'}
              </StartButton>
            ) : null}

          </>
        ) : (
          <StartButton onClick={startConversation}>Iniciar registro</StartButton>
        )}
        {showConfirmationButton && (
          <StartButton 
          onClick={enviarDatosAlServidor} 
          style={{ 
            marginTop: '10px', 
            marginBottom: '10px', 
            marginLeft: '10px', 
            marginRight: '10px'
          }}>
            Si tu información es correcta, presiona este botón para empezar el registro
        </StartButton>
        
        )}

        <div ref={messagesEndRef} />
      </ChatBox>

      <InputBox>
        <MessageInput
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleSend()}
          placeholder="Escribe un mensaje..."
          disabled={!conversationStarted}
        />
        <SendButton onClick={handleSend} disabled={!conversationStarted}>
          <FaPaperPlane />
        </SendButton>
      </InputBox>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <ModalContent>
          <ModalHeader>Para poder empezar tu registro necesitamos los siguientes datos</ModalHeader>
          <ModalInputContainer>
            <ModalInput value={palabra} onChange={(e) => setPalabra(e.target.value)} placeholder="Nombre de la marca" />
            <StyledTooltip text="Ingresa el nombre de la marca que quieres registrar" />

            <ModalInput
              value={valorReservable}
              onChange={(e) => setValorReservable(e.target.value)}
              placeholder="Elementos no reservables"
            />
            <StyledTooltip text="Elementos no reservables son frases comunes como Hecho en México" />

            <ModalInput value={traduccion} onChange={(e) => setTraduccion(e.target.value)} placeholder="Traducción" />
          </ModalInputContainer>

          <ModalTextarea
            value={descripcion}
            onChange={(e) => setDescripcion(e.target.value)}
            placeholder="Dame una descripción de tu marca"
          />
          <StyledTooltip text="Ingrese la traducción relevante" />
          <ModalActions>
            <ModalButton onClick={closeModal}>Cerrar</ModalButton>
            <ModalButton onClick={handleGuardar}>Guardar</ModalButton>
          </ModalActions>
        </ModalContent>
      </Modal>
      <Modal isOpen={isModalOpen2} onClose={closeModal2}>
        <SecondModalContent>
          <SecondModalHeader>Para poder registrar tu marca necesitamos los datos del dueño de la marca</SecondModalHeader>
          <ModalInputContainer>
            <LeftColumn>
              <SecondModalInput value={curp} onChange={(e) => setCurp(e.target.value)} placeholder="CURP" />
              <SecondModalInput
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Nombres"
              />
              <SecondModalInput value={apellido1} onChange={(e) => setApellido1(e.target.value)} placeholder="Apellido paterno" />
              <SecondModalInput value={apellido2} onChange={(e) => setApellido2(e.target.value)} placeholder="Apellido materno" />
              <SecondModalInput value={nacionalidad} onChange={(e) => setNacionalidad(e.target.value)} placeholder="Nacionalidad" />
              <SecondModalInput value={telefono} onChange={(e) => setTelefono(e.target.value)} placeholder="Telefono" />
            </LeftColumn>
            <RightColumn>
              <SecondModalInput value={correo} onChange={(e) => setCorreo(e.target.value)} placeholder="Correo" />
              <SecondModalInput value={calle} onChange={(e) => setCalle(e.target.value)} placeholder="Calle" />
              <SecondModalInput value={exterior} onChange={(e) => setExterior(e.target.value)} placeholder="Num Exterior" />
              <SecondModalInput value={interior} onChange={(e) => setInterior(e.target.value)} placeholder="Num interior" />
              <SecondModalInput value={cp} onChange={(e) => setCP(e.target.value)} placeholder="Codigo postal" />
              <SecondModalInput value={col} onChange={(e) => setCol(e.target.value)} placeholder="Colonia" />
              <SecondModalInput value={muni} onChange={(e) => setMuni(e.target.value)} placeholder="Municipio" />
              <SecondModalInput value={estado} onChange={(e) => setEstado(e.target.value)} placeholder="Estado" />
            </RightColumn>
          </ModalInputContainer>
          <ModalActions>
            <ModalButton onClick={closeModal2}>Cerrar</ModalButton>
            <ModalButton onClick={handleGuardar2}>Guardar</ModalButton>
          </ModalActions>
        </SecondModalContent>
      </Modal>
    </AppContainer>
  );
}

export default App;
