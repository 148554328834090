import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import { useAuth } from '../../../utils/AuthContext';
import Iconify from '../../../components/iconify';
import api from '../../../utils/api';

export default function SignupForm() {
  const navigate = useNavigate();
  const { signUp } = useAuth(); // Asegúrate de que 'signUp' esté definido en tu contexto de autenticación

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [profileUrl, setProfileUrl] = useState("");
  const [loading, setLoading] = useState("");
  const [token, setToken] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [urlImagenPerfil, setUrlImagenPerfil] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSignup = async () => {
    setErrorMessage("");
    setLoading(true); // Iniciar el estado de carga

    if (!email || !password || !name || !phoneNumber || !token) {
      setErrorMessage("Por favor, verifica que todos los campos estén correctamente llenados.");
      setAlertVisible(true);
      setLoading(false); // Detener el estado de carga
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage("Las contraseñas no coinciden. — ¡Verifica tus datos!");
      setAlertVisible(true);
      setLoading(false); // Detener el estado de carga
      return;
    }

    const checkUserPayload = {
      correo: email,
      token
    };

    try {
      const checkUserResponse = await api.post(`${process.env.REACT_APP_BACKEND_URL}/checkUser`, checkUserPayload);

      if (checkUserResponse.status === 200) {
        const registerPayload = {
          nombre: name,
          contraseña: password,
          correo: email,
          cell: phoneNumber,
          url: profileUrl
        };

        try {
          const registerResponse = await api.post(`${process.env.REACT_APP_BACKEND_URL}/register`, registerPayload);

          if (registerResponse.status === 200 || registerResponse.status === 201) {
            try {
              setAlertVisible(false);
              setLoading(false); // Detener el estado de carga
              navigate('/rutaPostRegistro'); // Navegar a la ruta deseada tras el registro
            } catch (error) {
              console.error("Error al eliminar el usuario de CrearUser:", error);
              setLoading(false); // Detener el estado de carga
            }
          }
        } catch (registerError) {
          console.error("Error en el registro:", registerError);
          setErrorMessage("Error en el registro.");
          setAlertVisible(true);
          setLoading(false); // Detener el estado de carga
        }
      } else if (checkUserResponse.status === 220) {
        setErrorMessage(`No se encontraron coincidencias de registros con el correo ${email} y el token ${token}. Por favor, revisa si son los datos correctos.`);
        setAlertVisible(true);
        setLoading(false); // Detener el estado de carga
      }
    } catch (checkUserError) {
      setErrorMessage("Ha ocurrido un error al verificar el usuario. Por favor, intenta nuevamente.");
      setAlertVisible(true);
      setLoading(false); // Detener el estado de carga
    }
  };

  useEffect(() => {
    if (alertVisible) {
      const timer = setTimeout(() => {
        setAlertVisible(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
    return undefined;
  }, [alertVisible]);

  return (
    <>
      {alertVisible && (
        <Alert severity="error" sx={{ marginBottom: 3 }}>
          <AlertTitle>Error</AlertTitle>
          {errorMessage}
        </Alert>
      )}

      <Stack spacing={3}>
        <TextField
          name="name"
          label="Nombre"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        <TextField
          name="email"
          label="Correo Electrónico"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <TextField
          name="phoneNumber"
          label="Número de Teléfono"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />

        <TextField
          name="profileUrl"
          label="URL de Imagen de Perfil (Opcional)"
          value={profileUrl}
          onChange={(e) => setProfileUrl(e.target.value)}
        />

        <TextField
          name="token"
          label="Token"
          value={token}
          onChange={(e) => setToken(e.target.value)}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'warning.main', // Color de borde cuando no está enfocado
              },
              '&:hover fieldset': {
                borderColor: 'warning.dark', // Color de borde al pasar el mouse por encima
              },
              '&.Mui-focused fieldset': {
                borderColor: 'warning.main', // Color de borde cuando está enfocado
              },
            },
          }}
        />



        <TextField
          name="password"
          label="Contraseña"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          name="confirmPassword"
          label="Confirmar Contraseña"
          type={showPassword ? 'text' : 'password'}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      <br />

      <LoadingButton
  loading={loading}
  disabled={loading} 
  fullWidth
  size="large"
  type="submit"
  variant="contained"
  onClick={handleSignup}
>
  Registrarse
</LoadingButton>

    </>
  );
}