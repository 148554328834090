import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import { useAuth } from '../../../utils/AuthContext'; 
import Iconify from '../../../components/iconify';
import api from '../../../utils/api';

export default function LoginForm() {
  const navigate = useNavigate();
  const { login } = useAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);

  const handleLogin = async () => {
    const payload = {
      correo: email,
      contraseña: password
    };
  
    try {
      const response = await api.post(`${process.env.REACT_APP_BACKEND_URL}/login`, payload);
  
      // Suponiendo que response.data contiene un objeto de usuario
      // y que quieres guardar toda la información de ese usuario
      const userData = response.data.user;
  
      // Guardar la información del usuario en localStorage
      // Esto podría ser redundante si tu AuthContext ya lo maneja
      localStorage.setItem('user', JSON.stringify(userData));
  
      // Llamar a login con el objeto de usuario
      login(userData);  
      navigate('/dashboard/app', { replace: true });
    } catch (error) {
      console.error("Error al iniciar sesión:", error);
  
      if (error.response && (error.response.status === 400 || error.response.status === 401)) {
        setAlertVisible(true);
      }
    }
  };
  
  

  useEffect(() => {
    if (alertVisible) {
      const timer = setTimeout(() => {
        setAlertVisible(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
    return undefined;
  }, [alertVisible]);

  return (
    <>
      {alertVisible && (
        <Alert severity="error" sx={{ marginBottom: 3 }}>
          <AlertTitle>Error</AlertTitle>
          Correo o contraseña incorrectos. — <strong>¡Verifica tus datos!</strong>
        </Alert>
      )}

      <Stack spacing={3}>
        <TextField 
          name="email" 
          label="Email address" 
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Checkbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack> */}
      <br/>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleLogin}>
        Login
      </LoadingButton>
    </>
  );
}
