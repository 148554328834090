import styled, { createGlobalStyle } from 'styled-components';
import Tooltip from '../components/Tooltip';

export const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%; // Cambia de 100vh a 100% si estás dentro de otro contenedor que ya ocupa 100vh
  width: 100%;
  background-color: #f0f0f0;
`;

export const ChatBox = styled.div`
  flex-grow: 1; // Esto hará que ChatBox ocupe todo el espacio disponible
  overflow-y: auto;
  padding: 20px;
  padding-bottom: 60px; // Ajusta este valor a la altura del InputBox
  background-color: #e0e0e0;
`;


export const InputBox = styled.div`
position: fixed; // Posicionamiento fijo
bottom: 0; // Alineado en la parte inferior
left: 295px; // Alineado a la derecha del sidebar que suponemos tiene 250px de ancho
right: 0; // Asegura que el InputBox se extienda hasta el final de la ventana
display: flex;
justify-content: space-between;
align-items: center;
padding: 15px;
background-color: #b0b0b0;

  // Media query dentro de styled-components
  @media (max-width: 768px) {
    left: 0; // El sidebar puede estar oculto en pantallas pequeñas
    width: 100%; // El InputBox puede ocupar todo el ancho
  }
`;



export const MessageInput = styled.input`
  flex: 1;
  padding: 10px;
  border-radius: 20px;
  border: none;
  outline: none;
  background-color: #d0d0d0; // Gris medio para el input
  color: #000000; // Texto negro
`;

export const MessageBubble = styled.div`
  padding: 10px 15px;
  border-radius: 15px;
  max-width: 70%;
  word-wrap: break-word;
  background-color: ${(props) =>
    props.sender === 'server' ? '#0078FF' : '#0099FF'}; // Azul más fuerte para el servidor, azul claro para el usuario
  color: white;
  text-align: left;
`;

export const GlobalStyle = createGlobalStyle`
.rounded-img {
  border-radius: 50%;
}
.nextui-modal {
  z-index: 2000 !important; // Asegurarnos de que tenga un z-index alto
}
  ${(props) =>
    props.modalOpen &&
    `
    body > div:not(#modal-root) {
      filter: blur(5px);
    }
  `}
`;

export const SendButton = styled.button`
  background: none;
  border: none;
  color: #ffffff;
  font-size: 24px;
`;

export const StartButton = styled.button`
  background-color: green;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 16px;
`;

export const AvatarContainer = styled.div`
  margin: 0 10px;
`;

export const StyledMessage = styled.div`
  display: flex;
  justify-content: ${(props) => (props.sender === 'server' ? 'flex-start' : 'flex-end')};
  align-items: center;
  margin: 10px 0;
`;

export const ModalInputContainer = styled.div`
  display: flex;
  flex-direction: column;  // Change this line
  width: 100%;

  input,
  textarea {
    flex: 1;
    margin-bottom: 10px;  // Add a margin-bottom for spacing between inputs
  }
`;


export const ModalHeader = styled.div`
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: 600;
  width: 100%;
  text-align: center;
`;

export const EditInfoButton = styled.button`
  background-color: #ff9900; // Naranja para el botón de editar
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 15px;
  align-self: center;
`;
export const ModalInput = styled.input`
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
  &:hover + div {
    // Esto asume que el Tooltip es el siguiente elemento tras el input al pasar el mouse por encima
    display: block; // o puedes usar otra propiedad para mostrarlo si tienes otra lógica implementada
  }
`;

export const ModalTextarea = styled.textarea`
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
  resize: vertical;
  &:hover + div {
    // Lo mismo para el textarea
    display: block;
  }
`;

export const StyledTooltip = styled(Tooltip)`
  margin-left: 10px;
  z-index: -1; // Asegurarse de que sea mayor que cualquier otro z-index en tu aplicación
  position: absolute; // Asegúrate de que sea absoluto para que se coloque correctamente.
`;


export const ModalActions = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  gap: 10px; // Espaciado entre los botones
`;

export const ModalButton = styled.button`
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px; // Margen a los botones para que no estén juntos
  &:first-child {
    background-color: #f0f0f0;
  }
  &:last-child {
    background-color: #0078ff;
    color: white;
  }
`;

export const ModalContent = styled.div`
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  overflow: visible;
`;
export const ModalContent1 = styled.div`
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  overflow: visible;
`;
export const ServerMessageBubble = styled(MessageBubble)`
  background-color: #0078ff; // Azul más fuerte para el servidor
  color: white;
  text-align: left;
`;

export const ServerStyledMessage = styled(StyledMessage)`
  justify-content: flex-start;
`;


export const SecondModalContent = styled(ModalContent1)`
  background-color: #fafafa; // Un fondo más claro para el segundo modal
`;
export const LeftColumn = styled.div`
  flex: 0.5;  // Ocupa la mitad del espacio disponible
  padding: 10px;  // Añade algo de padding para separar del contenido de la columna derecha
`;

export const RightColumn = styled.div`
  flex: 0.5;  // Ocupa la mitad del espacio disponible
  padding: 10px;  // Añade algo de padding para separar del contenido de la columna izquierda
`;


export const SecondModalHeader = styled(ModalHeader)`
  font-size: 20px;
  color: #333333; // Un color de texto más oscuro
  border-bottom: 1px solid #e0e0e0; // Una línea divisoria sutil
  padding-bottom: 10px;
  margin-bottom: 20px;
`;

export const SecondModalInput = styled(ModalInput)`
  border: 1px solid #0078ff; // Un borde azul para indicar el foco
  &:focus {
    box-shadow: 0 0 5px rgba(0, 120, 255, 0.5); // Un poco de sombra al enfocar
  }
`;

export const SecondModalTextarea = styled(ModalTextarea)`
  border: 1px solid #0078ff; // Un borde azul para indicar el foco
  &:focus {
    box-shadow: 0 0 5px rgba(0, 120, 255, 0.5); // Un poco de sombra al enfocar
  }
`;

export const SecondModalButton = styled(ModalButton)`
  background-color: #ff9900; // Un naranja vibrante para el botón principal
  color: white;
  &:hover {
    background-color: #ffac33; // Un poco más claro al pasar el cursor por encima
  }
`;