import { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [authState, setAuthState] = useState(() => {
    // Intenta recuperar el estado de autenticación y los datos de usuario del localStorage
    const savedSession = localStorage.getItem('isAuthenticated');
    const savedUser = localStorage.getItem('user');
    return {
      isAuthenticated: savedSession ? JSON.parse(savedSession) : false,
      user: savedUser ? JSON.parse(savedUser) : null,
    };
  });

  const login = (user) => {
    setAuthState({
      isAuthenticated: true,
      user,
    });
    localStorage.setItem('isAuthenticated', 'true');
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
    }
  };

  const logout = () => {
    setAuthState({
      isAuthenticated: false,
      user: null,
    });
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('user');
  };

  useEffect(() => {
    // Actualiza el localStorage cuando cambia el estado de autenticación
    localStorage.setItem('isAuthenticated', JSON.stringify(authState.isAuthenticated));
    // Si hay un usuario, actualiza el localStorage, si no, remueve el item
    if (authState.user) {
      localStorage.setItem('user', JSON.stringify(authState.user));
    } else {
      localStorage.removeItem('user');
    }
  }, [authState.isAuthenticated, authState.user]);

  return (
    <AuthContext.Provider value={{ ...authState, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}
