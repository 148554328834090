import React, { useState, useEffect } from 'react';
import { Container, TextField, Button, Typography, Stack, IconButton, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Paper, CircularProgress } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import WarningAmberIcon from '@mui/icons-material/WarningAmber'; // Ícono para "Aviso"
import UpdateIcon from '@mui/icons-material/Update'; // Ícono para "Actualización"
import InfoIcon from '@mui/icons-material/Info'; // Ícono para "Noticia"
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant'; // Ícono para "Alerta"


const AddNotification = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [links, setLinks] = useState([{ title: '', href: '' }]);
  const [destinations, setDestinations] = useState([]);
  const [destinationInputValue, setDestinationInputValue] = useState('');
  const [notificationType, setNotificationType] = useState('');
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const NotificationTypeIcon = ({ type }) => {
    const iconStyle = { marginRight: 8 }; // Estilo para iconos

    switch (type) {
      case 'alert':
        return <NotificationImportantIcon color="error" style={iconStyle} />;
      case 'warning':
        return <WarningAmberIcon color="warning" style={iconStyle} />;
      case 'update':
        return <UpdateIcon color="success" style={iconStyle} />;
      case 'news':
        return <InfoIcon color="primary" style={iconStyle} />;
      default:
        return null;
    }
  };

  const handleNotificationTypeChange = (event) => {
    setNotificationType(event.target.value);
  };

  const handleAddLink = () => {
    setLinks([...links, { title: '', href: '' }]);
  };

  const handleRemoveLink = (index) => {
    setLinks(links.filter((_, linkIndex) => linkIndex !== index));
  };

  const handleLinkChange = (index, type, value) => {
    const newLinks = [...links];
    newLinks[index][type] = value;
    setLinks(newLinks);
  };


  // Función para agregar una nueva notificación (esto sería en realidad una llamada API o alguna otra acción)
  const handleSubmit = async () => {
    setIsLoading(true);
    // Construcción del objeto de notificación con la estructura deseada
    const notificationData = {
      titulo: title,
      descripcion: description,
      tipo: notificationTypeValues[notificationType], // Utiliza el mapa para obtener el valor numérico
      origen: 1, // Origen siempre es 1
      anon: false, // Anon siempre es false
      links: links.map(link => ({ name: link.title, url: link.href })), // Mapea los links para ajustar a la estructura deseada
      destinos: destinations // Ya contiene los IDs de los usuarios seleccionados
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/notifications`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(notificationData), // Convierte el objeto de notificación a JSON
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log(result); // Imprime la respuesta del servidor
      setIsLoading(false);
    } catch (error) {
      console.error("There was a problem sending the notification data:", error);
      setIsLoading(false);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setUsers(data); // Actualiza el estado de usuarios con los datos obtenidos
    } catch (error) {
      console.error("There was a problem fetching the users data:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  // Autocompletado de destinos (deberías reemplazar esto con tus datos reales)
  const destinationOptions = ['Usuario 1', 'Usuario 2', 'Grupo 1', 'Grupo 2'];

  // Manejador para seleccionar destinos. Aquí se actualiza para manejar objetos en lugar de strings.
  const handleDestinationSelect = (_, value) => {
    setDestinations(value.map(user => user.id)); // Almacena los IDs de los usuarios seleccionados
  };

  // Mapeo de los tipos de notificación a su valor numérico correspondiente
  const notificationTypeValues = {
    'alert': 1,
    'warning': 2,
    'update': 3,
    'news': 4
  };


  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        Agregar Notificación
      </Typography>
      <Stack spacing={3}>
        <TextField
          label="Título"
          variant="outlined"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <TextField
          label="Descripción"
          variant="outlined"
          multiline
          rows={4}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        {links.map((link, index) => (
          <Stack key={index} direction="row" alignItems="center" spacing={2}>
            <TextField
              label="Nombre del enlace"
              variant="outlined"
              value={link.title}
              onChange={(e) => handleLinkChange(index, 'title', e.target.value)}
              fullWidth
            />
            <TextField
              label="URL"
              variant="outlined"
              value={link.href}
              onChange={(e) => handleLinkChange(index, 'href', e.target.value)}
              fullWidth
            />
            <IconButton onClick={() => handleRemoveLink(index)}>
              <DeleteIcon />
            </IconButton>
          </Stack>
        ))}
        <Button startIcon={<AddIcon />} onClick={handleAddLink}>
          Agregar Link
        </Button>
        <Autocomplete
          multiple
          id="autocomplete-users"
          options={users} // Usa el estado de usuarios como opciones
          getOptionLabel={(option) => option.nombre} // Utiliza el nombre del usuario para la etiqueta
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={handleDestinationSelect}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Destino"
              placeholder="Buscar usuario"
            />
          )}
        />
        <Paper variant="outlined" sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Tipo de Notificación</FormLabel>
            <RadioGroup
              row
              aria-label="notification-type"
              name="notificationType"
              value={notificationType}
              onChange={handleNotificationTypeChange}
              sx={{
                justifyContent: 'center',
                flexWrap: 'wrap',
                gap: 2,
                '& .MuiSvgIcon-root': { // Estilos para los íconos
                  fontSize: '1.5rem',
                },
              }}
            >
              {['alert', 'warning', 'update', 'news'].map((type) => (
                <FormControlLabel
                  key={type}
                  value={type}
                  control={<Radio />}
                  label={<><NotificationTypeIcon type={type} />{type.charAt(0).toUpperCase() + type.slice(1)}</>}
                  labelPlacement="bottom"
                  sx={{
                    flexDirection: 'column',
                    '& .MuiFormControlLabel-label': { // Estilo para la etiqueta
                      marginTop: 1,
                      textTransform: 'capitalize',
                    },
                  }}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Paper>

        {isLoading ? (
          <CircularProgress /> // Muestra el indicador de carga si isLoading es true
        ) : (
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Guardar Notificación
          </Button>
        )}
      </Stack>
    </Container>
  );
};

export default AddNotification;
