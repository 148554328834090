import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Grid, Avatar, Typography, Stack, Button, Chip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import FaceIcon from '@mui/icons-material/Face';
import IconButton from '@mui/material/IconButton';
import WarningIcon from '@mui/icons-material/Warning';
import { DataGrid } from '@mui/x-data-grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import api from '../utils/api';

// Helper function to generate verification chip
const VerificationChip = ({ isVerified, label }) => (
  <Chip
    icon={<FaceIcon />}
    label={isVerified ? `${label} verificado` : `${label} no verificado`}
    style={{
      backgroundColor: isVerified ? 'green' : 'red',
      color: 'white',
      margin: 5
    }}
  />
);

const ExpandableText = ({ text, maxLength, onHeightChange }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const contentRef = useRef(null);

  const handleToggle = () => {
    // Cambia el estado de isExpanded al opuesto de lo que es actualmente
    setIsExpanded((prev) => !prev);
  };

  useEffect(() => {
    // Si isExpanded es true, entonces configura la altura a la altura total del contenido
    // Si isExpanded es false, entonces configura la altura a 'auto' para que se colapse
    if (contentRef.current) {
      onHeightChange(isExpanded ? contentRef.current.scrollHeight : 'auto');
    }
  }, [isExpanded]); // Solo se vuelve a ejecutar este efecto si isExpanded cambia

  // El contenido de return es lo que se renderiza en el componente
  return (
    <div ref={contentRef} style={{ height: isExpanded ? 'auto' : undefined }}>
      {isExpanded ? text : `${text.substring(0, maxLength)}... `}
      <Button onClick={handleToggle}>
        {isExpanded ? 'ver menos' : 'ver más...'}
      </Button>
    </div>
  );
};





const MAX_LENGTH = 15;


const UserProfile = () => {
  const [avatarUrl, setAvatarUrl] = useState('');
  const [Name, setName] = useState('');
  const [Cell, setCell] = useState('');
  const [email, setEmail] = useState('');
  const [idUser, setId] = useState('');
  const [varified, setVarified] = useState({});
  const [procesoStatus, setProcesoStatus] = useState(false);
  const [brandData, setBrandData] = useState([]);
  const { img, Nombre } = varified;
  const [rowHeights, setRowHeights] = useState({});
  const [numRegistrosDisponibles, setNumRegistrosDisponibles] = useState('');

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('user'));
    if (userData && userData.correo) {
      const fetchNumRegistros = async () => {
        try {
          const response = await api.get(`${process.env.REACT_APP_BACKEND_URL}/numRegistros/correo/${userData.correo}`);
          if (response.data && response.data.numR !== undefined) {
            setNumRegistrosDisponibles(response.data.numR);
          } else {
            console.error("No se encontraron datos para el correo especificado.");
          }
        } catch (error) {
          console.error("Error al obtener el número de registros disponibles:", error);
        }
      };

      fetchNumRegistros();
    }
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('user'));
    if (userData && userData.url) {
      setAvatarUrl(userData.url);
      setName(userData.nombre);
      setCell(userData.cell);
      setEmail(userData.correo);
      setId(userData.id);
    }
  }, []);

  const handleRowHeightChange = (id, height) => {
    setRowHeights((prevRowHeights) => ({
      ...prevRowHeights,
      [id]: height,
    }));
  };
  

  useEffect(() => {
    const getLastRecord = async () => {
      try {
        const response = await api.get(`${process.env.REACT_APP_BACKEND_URL}/v_marcia/last_record/${idUser}`);
        setVarified(response.data.lastRecord);
        // Suponiendo que 'proceso' es una de las propiedades devueltas
        setProcesoStatus(response.data.lastRecord.proceso === 1);
      } catch (error) {
        console.error("Error retrieving last record:", error);
      }
    };

    if (idUser) {
      getLastRecord();
    }
  }, [idUser]); // Dependiendo de idUser

  useEffect(() => {
    const getLastRecord = async () => {
      try {
        const response = await api.get(`${process.env.REACT_APP_BACKEND_URL}/v_marcia/last_record/${idUser}`);
        setVarified(response.data.lastRecord)
      } catch (error) {
        console.error("Error retrieving last record:", error);
      }
    };

    // If idUser exists, make the API call
    if (idUser) {
      getLastRecord();
    }
  }, [idUser]); // The effect depends on idUser, so it reruns when idUser changes



  const StatusChip = ({ status }) => (
    <Chip
      style={{
        backgroundColor: status ? 'green' : 'red', // green for true/active, red for false/inactive
        width: 12,
        height: 12,
        margin: 5,
      }}
    />
  );

  const ReportProblemChip = () => (
    <Chip
      icon={<WarningIcon />}
      style={{
        backgroundColor: 'yellow',
        color: 'black',
        margin: 5,
      }}
    />
  );

  const NoRowsOverlay = () => (
    <div style={{ textAlign: 'center', padding: '10px' }}>
      <Typography variant="subtitle1">No hay datos registrados</Typography>
    </div>
  );
  

  const ReportProblemButton = () => (
    <IconButton
      href="https://api.whatsapp.com/send/?phone=5219514199661&text=Hola%2C+necesito+m%C3%A1s+informaci%C3%B3n+Businessverso+https%3A%2F%2Fbusinessverso.com.mx&type=phone_number&app_absent=0"
      target="_blank"
      size="small"
      style={{ color: 'orange' }} // Adjust styling as needed
    >
      <WarningIcon />
    </IconButton>
  );


  // Columns for DataGrid
  const columns = [
    { field: 'brandName', headerName: 'Nombre de marca', width: 150 },
    {
      field: 'description',
      headerName: 'Descripción',
      width: 250,
      renderCell: (params) => (
        <span title={params.value}>{params.value}</span> // Agregado title para mostrar tooltip
      ),
    },
    { field: 'niza', headerName: 'Niza', width: 100 },
    {
      field: 'status',
      headerName: 'Estatus',
      width: 130,
      renderCell: (params) => <StatusChip status={params.value} />,
    },
    {
      field: 'problema',
      headerName: 'Reportar problema',
      width: 130,
      renderCell: () => <ReportProblemButton />,
    },
    // ... other columns as needed
  ];

  const fetchUserData = async () => {
    if (idUser) {
      try {
        const response = await api.post(`${process.env.REACT_APP_BACKEND_URL}/leerDatos`, { id: idUser });
        // Asegúrate de que la respuesta tiene la estructura que esperas
        const userData = response.data.map(item => ({
          id: item.id, // Asegúrate de que estos campos correspondan con tu respuesta
          brandName: item.palabra,
          description: item.descripcion,
          niza: item.niza,
          status: item.proceso
        }));
  
        setBrandData(userData); // Actualiza el estado con los nuevos datos
      } catch (error) {
        console.error("Error fetching data from leerDatos:", error.response ? error.response.data : error.message);
      }
    }
  };
  


  // Call fetchUserData when idUser changes (or component mounts if idUser is available initially)
  useEffect(() => {
    fetchUserData();
  }, [idUser]);


  return (
    <>
      <Helmet>
        <title>User Profile</title>
      </Helmet>
      <Container>
        <Stack direction="column" spacing={2} alignItems="center" mb={5}>
          <Typography variant="h4" gutterBottom>
            User Profile
          </Typography>
          <Button variant="contained" startIcon={<EditIcon />} size="small">
            Edit Profile
          </Button>
        </Stack>

        <Grid container spacing={2}>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <Avatar alt="User Name" src={avatarUrl} sx={{ width: 80, height: 80 }} />
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Typography variant="h6" gutterBottom>
              {Name}
            </Typography>
            <Typography variant="body2" gutterBottom>
              Email: {email}
            </Typography>
            <Typography variant="body2" gutterBottom>
              Cell: {Cell}
            </Typography>
            <Typography variant="body2" gutterBottom>
            Número disponibles de registros: {numRegistrosDisponibles}
          </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ marginTop: 2, overflowX: 'auto' }}>
          <Stack direction="row" spacing={1} justifyContent="center" flexWrap="wrap">
            <VerificationChip isVerified={img} label="Logo" size="small" />
            <VerificationChip isVerified={Nombre} label="Marca" size="small" />
            <VerificationChip isVerified={procesoStatus} label="Proceso" size="small" />
          </Stack>
        </Grid>

        <Grid item xs={12} style={{ height: 'auto', width: '100%', marginTop: '20px' }}>
          <DataGrid
            rows={brandData}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            checkboxSelection
            getRowHeight={(params) => rowHeights[params.id] || 'auto'}
            autoHeight
            rowCount={brandData.length}
            components={{
              NoRowsOverlay,
            }}
          />
        </Grid>
      </Container>
    </>
  );


};

export default UserProfile;
