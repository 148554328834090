import PropTypes from 'prop-types';
import { set, sub } from 'date-fns';
import { noCase } from 'change-case';
import { faker } from '@faker-js/faker';
import { useEffect, useState } from 'react';
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Popover,
  Typography,
  IconButton,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton
} from '@mui/material';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import WarningAmberIcon from '@mui/icons-material/WarningAmber'; // Ícono para "Aviso"
import UpdateIcon from '@mui/icons-material/Update'; // Ícono para "Actualización"
import InfoIcon from '@mui/icons-material/Info'; // Ícono para "Noticia"
import DeleteIcon from '@mui/icons-material/Delete';

// utils
import { fToNow } from '../../../utils/formatTime';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';

// ----------------------------------------------------------------------
const AVATAR_ICONS = {
  '1': <NotificationImportantIcon sx={{ color: 'error.main' }} />,
  '2': <WarningAmberIcon sx={{ color: 'warning.dark' }} />,
  '3': <UpdateIcon sx={{ color: 'info.main' }} />,
  '4': <InfoIcon sx={{ color: 'primary.main' }} />, // Usando InfoIcon aquí
};




export default function NotificationsPopover() {

  const [notificationCount, setNotificationCount] = useState(0);
  const [open, setOpen] = useState(null);
  const [userId, setUserId] = useState(null); // Asegúrate de que este estado se actualiza correctamente
  const [notifications, setNotifications] = useState([]); // Asumiendo que esto se actualizará con notificaciones

  // Esta función debe estar definida dentro de NotificationsPopover para acceder a `setNotifications` y `userId`
  const handleDeleteNotification = (notificationId) => {
    const requestBody = {
      id_notification: notificationId,
      id_destino: userId, // Usar camelCase
    };
  
    // Imprimir en la consola el cuerpo de la petición y el userId
    console.log("Enviando petición para borrar notificación:", requestBody);
    console.log("UserID:", userId);
  
    fetch(`${process.env.REACT_APP_BACKEND_URL}/notifications/destino`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        console.log("Respuesta de la petición:", response);
        // Actualiza el estado para reflejar la notificación eliminada
        setNotifications(prevNotifications => prevNotifications.filter(notif => notif.id !== notificationId));
      })
      .catch((error) => {
        console.error('Error al borrar la notificación:', error);
      });
  };
  


  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMarkAllAsRead = () => {
    // Aquí deberías llamar a una API para marcar todas como leídas, si existe esa funcionalidad.
    setNotifications(
      notifications.map((notification) => ({
        ...notification,
        isUnRead: false,
      }))
    );
  };

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('user'));
    if (userData && userData.id) {
      setUserId(userData.id);
    }
  }, []);

  useEffect(() => {
    if (userId) {
      // Llamada a la API para obtener el conteo de notificaciones no leídas
      fetch(`${process.env.REACT_APP_BACKEND_URL}/notifications/count/${userId}`)
        .then((response) => response.json())
        .then((data) => {
          // Suponiendo que 'count' es el número de notificaciones no leídas que devuelve la API
          setNotificationCount(data.count);
        })
        .catch((error) => {
          console.error('Error al obtener el conteo de notificaciones:', error);
        });

      // Llamada a la API para obtener las notificaciones
      fetch(`${process.env.REACT_APP_BACKEND_URL}/notifications/destino/${userId}`)
        .then((response) => response.json())
        .then((data) => {
          const fetchedNotifications = data.notifications
            .map((notif) => ({
              id: notif.id,
              title: notif.titulo,
              description: notif.descripcion,
              avatar: AVATAR_ICONS[notif.tipo.toString()], // Asignar el icono de Material UI correspondiente al tipo
              type: notif.tipo,
              createdAt: new Date(notif.createdAt),
              isUnRead: notif.isUnRead,
              links: notif.links_notifications, // Agregamos los enlaces aquí
            }))
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)); // Ordenar por fecha, la más reciente primero
          setNotifications(fetchedNotifications);
        })
        .catch((error) => {
          console.error('Error al obtener las notificaciones:', error);
        });

    }
  }, [userId]);


  const totalUnRead = notifications.filter((item) => item.isUnRead).length;


  return (
    <>
      <IconButton color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 40, height: 40 }}>
        <Badge badgeContent={notificationCount} color="error">
          <Iconify icon="eva:bell-fill" />
        </Badge>
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{ sx: { mt: 1.5, ml: 0.75, width: 360 } }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {totalUnRead} unread messages
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title="Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          <List disablePadding subheader={<ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>New</ListSubheader>}>
            {notifications.slice(0, 2).map((notification) => (
              <NotificationItem key={notification.id} notification={notification} onDelete={handleDeleteNotification} />
            ))}
          </List>

          <List disablePadding subheader={<ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>Before that</ListSubheader>}>
            {notifications.slice(2).map((notification) => (
              <NotificationItem key={notification.id} notification={notification} onDelete={handleDeleteNotification} />
            ))}
          </List>
        </Scrollbar>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple>View All</Button>
        </Box>
      </Popover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.instanceOf(Date),
    id: PropTypes.string,
    isUnRead: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    avatar: PropTypes.any,
  }),
};

function NotificationItem({ notification, onDelete }) {
  const { avatar, title, links } = renderContent(notification);

  // Esta función ahora llama a onDelete, pasada como prop, con el id de la notificación
  const handleDelete = () => {
    onDelete(notification.id);
  };

  return (
    <ListItemButton sx={{ py: 1.5, px: 2.5, mt: '1px', bgcolor: notification.isUnRead ? 'action.selected' : 'inherit' }}>
      <ListItemAvatar>
        {avatar}
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <>
            <Typography variant="caption" sx={{ display: 'block', color: 'text.disabled' }}>
              {fToNow(notification.createdAt)}
            </Typography>
            {links.map(link => (
              <Button key={link.id} component="a" href={link.link} target="_blank" rel="noopener noreferrer" size="small" sx={{ my: 0.5 }}>
                {link.name}
              </Button>
            ))}
          </>
        }
      />
      <IconButton onClick={handleDelete} edge="end" aria-label="delete">
        <DeleteIcon />
      </IconButton>
    </ListItemButton>
  );
}


// ----------------------------------------------------------------------
function renderContent(notification) {
  // Obtiene el título y la descripción
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp;{noCase(notification.description)}
      </Typography>
    </Typography>
  );

  // Obtiene el avatar basado en el tipo de la notificación, que esperamos como número
  let avatar;
  switch (notification.type) {
    case 1:
      avatar = <NotificationImportantIcon sx={{ color: 'error.main' }} />;
      break;
    case 2:
      avatar = <WarningAmberIcon sx={{ color: 'warning.dark' }} />;
      break;
    case 3:
      avatar = <UpdateIcon sx={{ color: 'info.main' }} />;
      break;
    case 4:
      avatar = <InfoIcon sx={{ color: 'primary.main' }} />;
      break;
    default:
      avatar = <Avatar sx={{ bgcolor: 'background.neutral' }} />;
  }

  // Asegúrate de que links siempre sea un array
  const links = notification.links || [];

  return {
    avatar: <Avatar>{avatar}</Avatar>,
    title,
    links,
  };
}