import axios from 'axios';


import React, { useState, useEffect, useRef } from 'react';
import { FaPaperPlane } from 'react-icons/fa';
import Avatar from '@mui/material/Avatar';

import Alert from '@mui/material/Alert';
import styled, { createGlobalStyle, keyframes } from 'styled-components';

import api from '../utils/api';

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%; // Cambia de 100vh a 100% si estás dentro de otro contenedor que ya ocupa 100vh
  width: 100%;
  background-color: #f0f0f0;
`;

export const ChatBox = styled.div`
  flex-grow: 1; // Esto hará que ChatBox ocupe todo el espacio disponible
  overflow-y: auto;
  padding: 20px;
  padding-bottom: 60px; // Ajusta este valor a la altura del InputBox
  background-color: #e0e0e0;
`;


export const InputBox = styled.div`
position: fixed; // Posicionamiento fijo
bottom: 0; // Alineado en la parte inferior
left: 295px; // Alineado a la derecha del sidebar que suponemos tiene 250px de ancho
right: 0; // Asegura que el InputBox se extienda hasta el final de la ventana
display: flex;
justify-content: space-between;
align-items: center;
padding: 15px;
background-color: #b0b0b0;

  // Media query dentro de styled-components
  @media (max-width: 768px) {
    left: 0; // El sidebar puede estar oculto en pantallas pequeñas
    width: 100%; // El InputBox puede ocupar todo el ancho
  }
`;

const MessageInput = styled.input`
    flex: 1;
    padding: 10px;
    border-radius: 20px;
    border: none;
    outline: none;
    background-color: #d0d0d0;  // Gris medio para el input
    color: #000000;  // Texto negro
  `;

const GlobalStyle = createGlobalStyle`
    .rounded-img {
      border-radius: 50%;
    }
  `;

const ImageContainer = styled.div`
    display: flex;
    flex-wrap: wrap;   // Esto permite que los elementos pasen al siguiente renglón si no hay espacio
    justify-content: space-between;
    width: 210px; // Esto asume que cada imagen tiene un ancho de 100px y se quiere un espacio de 10px entre ellas
  `;

const ChatImage = styled.img`
    margin-bottom: 10px;  // Espacio en la parte inferior de cada imagen
    max-width: 100%; // Asegura que las imágenes no excedan el ancho del contenedor
  `;



const MessageContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: ${(props) => (props.sender === 'user' ? 'flex-end' : 'flex-start')};
  `;

const SendButton = styled.button`
    background: none;
    border: none;
    color: #ffffff;
    font-size: 24px;
  `;

const Message = styled.div`
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
    background-color: ${(props) => (props.sender === 'user' ? '#4caf50' : '#2196f3')};
    color: white;
    text-align: ${(props) => (props.sender === 'user' ? 'left' : 'right')};
  `;
const ImageLogoButton = styled.button`
    background-color: blue;
    color: white;
    padding: 10px 20px;
    margin: 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  `;


const typingAnimation = keyframes`
    0% {
      content: ".";
    }
    33% {
      content: "..";
    }
    66% {
      content: "...";
    }
    100% {
      content: ".";
    }
  `;

const TypingIndicator = styled.div`
    &:after {
      content: ".";
      animation: ${typingAnimation} 1.5s infinite;
      font-size: 25px;  // Aumenta el tamaño de la fuente aquí
    }
  `;

const StartButton = styled.button`
    background-color: green;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  `;

const NoButton = styled.button`
    background-color: red;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  `;

const FileInput = styled.input`
    margin: 10px;
  `;
const ContactButton = styled.a`
    background-color: #25D366; // color de WhatsApp
    color: white;
    margin-top: 10px;
    padding: 10px 20px;
    margin: 10px 5px 5px 5px; // agrega un margen superior de 10px
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none; // para quitar el subrayado del enlace
  `;


function App() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [conversationStarted, setConversationStarted] = useState(false);
  const messagesEndRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showWarning, setShowWarning] = useState(false);  // Nuevo estado para la advertencia
  const [imageFile, setImageFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [serverImages, setServerImages] = useState([]);
  const [showContactButton, setShowContactButton] = useState(false);
  const [optionSelected, setOptionSelected] = useState(false);
  const [isTextBranch, setIsTextBranch] = useState(false); // Nuevo estado para seguir la rama de "Texto"
  const [showLinkButton, setShowLinkButton] = useState(false);  // Nuevo estado para mostrar el botón de enlace
  const [avatarUrl, setAvatarUrl] = useState('');
  const [showReloadButton, setShowReloadButton] = useState(false);


  useEffect(() => {
    // Mensaje inicial del servidor
    if (messages.length === 0) {
      setMessages([{ text: '¿Quieres empezar la conversación?', sender: 'server', buttons: true }]);
    }
  }, [messages]);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('user'));
    if (userData && userData.url) {
      setAvatarUrl(userData.url);
    }
  }, []);

  const handleStart = () => {
    setConversationStarted(true);
    setMessages(prevMessages => [...prevMessages, {
      text: 'Primero tenemos que verificar si tu marca o logo ya existen, ¿qué quieres verificar, imagen o Palabra?',
      sender: 'server',
      imageLogoButtons: true
    }]);
  };

  const handleYes = () => {
    setOptionSelected(true);  // Actualizar el estado a true
    setMessages(prevMessages => [...prevMessages, {
      text: 'Te contactaremos con uno de nuestros administradores para poder solucionar tu caso',
      sender: 'server',
      contactButton: true
    }]);
  };

  const handleNoResponse = () => {
    setOptionSelected(true);  // Actualizar el estado a true
    setMessages(prevMessages => [...prevMessages, {
      text: 'Felicidades, ahora puedes pasar al siguiente paso, si tienes el nombre de tu marca y aun no la verificas con nosotros, recarga la pagina para empezar el proceso',
      sender: 'server'
    }]);
    sendToVMarcia2();
  };

  const handleNo = () => {
    setMessages([{ text: '¿Quieres empezar la conversación?', sender: 'server', buttons: true }]);
  };

  const handleImage = () => {
    setMessages(prevMessages => [...prevMessages, {
      text: 'Por favor, sube tu imagen.',
      sender: 'user',
      uploadImage: true  // Indicador para mostrar el input de archivo
    }]);
  };

  const handleFileChange = (event) => {
    setImageFile(event.target.files[0]);  // Guardar el archivo seleccionado en el estado
  };

  const handleFileUpload = async () => {
    if (!imageFile) return;

    setIsUploading(true);  // Indicar que la imagen se está subiendo

    const formData = new FormData();
    formData.append('file', imageFile);

    try {
      const response = await axios.post('http://localhost:5000/search_logo', formData);
      console.log(response.data);  // Imprimir la respuesta en la consola
      if (response.data.image_urls && response.data.image_urls.length) {
        setServerImages(response.data.image_urls);
        setMessages(prevMessages => [...prevMessages,
        { text: 'Imagenes encontradas:', sender: 'server', images: response.data.image_urls.slice(0, 10) },
        { text: '¿Hay algún logo idéntico al tuyo?', sender: 'server', yesNoButtons: true }  // Línea nueva
        ]);
      } else {
        setMessages(prevMessages => [...prevMessages, { text: 'No se encontraron imágenes.', sender: 'server' }]);
      }

    } catch (error) {
      console.error('Error uploading file:', error);
    }

    setIsUploading(false);  // Indicar que la imagen ya no se está subiendo 
  };


  const handleTexto = () => {
    setMessages(prevMessages => [...prevMessages, {
      text: 'Mándanos el nombre de tu marca',
      sender: 'server',
      waitForUserInput: true  // Este flag nos dirá que el servidor está esperando una entrada del usuario
    }]);
  };





  const handleSend = async () => {
    if (input.trim() === '') return;

    // Añadir el mensaje del usuario
    setMessages(prevMessages => [...prevMessages, { text: input, sender: 'user' }]);

    const payload = {
      term: input
    };

    try {
      const response = await axios.post('https://backimpi.businessadvocacy.shop/api/search', payload);
      console.log('Respuesta del servidor:', response.data);  // Imprimir la respuesta en la consola

      if (response.data.message === 'Results found.') {
        setMessages(prevMessages => [...prevMessages, {
          text: 'Ya hay un nombre de marca ya registrada, si deseas obtener mas detalles, accede al siguiente sitio web:',
          sender: 'server',
          showLinkButton: true
        }]);
      } else {
        setMessages(prevMessages => [...prevMessages, {
          text: 'Felicidades, puedes seguir con el registro de tu marca, si tienes un logo y aun no lo verificas, recarga la pagina para poder hacerlo, si no lo tienes puedes seguir al siguiente paso',
          sender: 'server'
        }]);
        sendToVMarcia();
      }
    } catch (error) {
      console.log('Error:', error);  // Imprimir el error en la consola
      setMessages(prevMessages => [...prevMessages, {
        text: 'Hubo un error al procesar tu solicitud',
        sender: 'server'
      }]);
    }

    setInput('');
  };




  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSend();
    }
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const sendToVMarcia = async () => {
    const userData = JSON.parse(localStorage.getItem('user'));
    const payload = {
      "user_id": userData.id, // Assuming the user's ID is stored under 'id'
      "Nombre": true
    };

    try {
      const response = await api.post(`${process.env.REACT_APP_BACKEND_URL}/v_marcia`, payload);
      console.log("Successfully sent to /v_marcia:", response.data);
    } catch (error) {
      console.error("Error sending to /v_marcia:", error);
    }
  };
  const sendToVMarcia2 = async () => {
    const userData = JSON.parse(localStorage.getItem('user'));
    const payload = {
      "user_id": userData.id, // Assuming the user's ID is stored under 'id'
      "img": true
    };

    try {
      const response = await api.post(`${process.env.REACT_APP_BACKEND_URL}/v_marcia`, payload);
      console.log("Successfully sent to /v_marcia:", response.data);
    } catch (error) {
      console.error("Error sending to /v_marcia:", error);
    }
  };

  return (
    <AppContainer>
      <GlobalStyle />
      <ChatBox>
        {messages.map((message, index) => (
          <MessageContainer key={index} sender={message.sender}>
            {message.sender === 'server' && <Avatar src="https://www.thoughtco.com/thmb/s0R5tBnS9ZZZ3u6BJXE86qpsC5E=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/GettyImages-154320249-569b9b3a3df78cafda95dda2.jpg" />}
            {message.uploadImage ? (
              <div>
                {message.text}
                <FileInput type="file" onChange={handleFileChange} />
                <ImageLogoButton onClick={handleFileUpload} disabled={isUploading}>
                  {isUploading ? 'Subiendo...' : 'Subir'}
                </ImageLogoButton>
              </div>
            ) : message.imageLogoButtons ? (
              <div>
                <div>{message.text}</div>
                <ImageLogoButton onClick={handleImage}>Imagen</ImageLogoButton>
                <ImageLogoButton onClick={handleTexto}>Palabra</ImageLogoButton>
              </div>
            ) : message.yesNoButtons ? (
              <div>
                <div>{message.text}</div>
                <StartButton onClick={handleYes} disabled={optionSelected}>Sí</StartButton>
                <NoButton onClick={handleNoResponse} disabled={optionSelected}>No</NoButton>

              </div>
            ) : message.contactButton ? (
              <div>
                <div>{message.text}</div>
                <br />
                <ContactButton
                  href="https://api.whatsapp.com/send/?phone=5219514199661&text=Hola%2C+necesito+m%C3%A1s+informaci%C3%B3n+Businessverso+https%3A%2F%2Fbusinessverso.com.mx&type=phone_number&app_absent=0"
                  target="_blank"  // esto abre el enlace en una nueva pestaña
                >
                  Contáctanos
                </ContactButton>
              </div>
            ) : message.buttons && !conversationStarted ? (
              <div>
                <StartButton onClick={handleStart} style={{ marginLeft: '10px', marginRight: '10px' }}>
                  Empezar Conversación
                </StartButton>
                <NoButton onClick={handleNo}>No</NoButton>

              </div>
            ) : message.type === 'warning' ? (
              <Alert severity="warning">{message.text}</Alert>
            ) : message.showLinkButton ? (
              <div>
                <div>{message.text}</div>
                <br />
                <ContactButton
                  style={{ marginTop: '10px !important' }}
                  href="https://marcia.impi.gob.mx/marcas/search/quick"
                  target="_blank"  // esto abre el enlace en una nueva pestaña
                >
                  Ver Detalles
                </ContactButton>
              </div>
            ) : (
              <Message sender={message.sender}>
                {message.isLoading ? <TypingIndicator /> : message.text}
                {message.images && (
                  <ImageContainer>
                    {message.images.map((imgUrl, imgIndex) => (
                      <ChatImage key={imgIndex} src={imgUrl} alt={`Imagen ${imgIndex}`} />
                    ))}
                  </ImageContainer>
                )}
              </Message>
            )}
            {message.sender === 'user' && <Avatar src={avatarUrl} />}
          </MessageContainer>
        ))}
        <div ref={messagesEndRef} />
        {showWarning && <Alert severity="warning">Esperando respuesta del servidor!</Alert>}
      </ChatBox>

      <InputBox>
        <MessageInput
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Escribe un mensaje..."
          disabled={!conversationStarted}
        />
        <SendButton onClick={handleSend} disabled={!conversationStarted || isLoading} > {/* Deshabilitar si la conversación no ha empezado o está cargando */}
          <FaPaperPlane />
        </SendButton>
      </InputBox>
    </AppContainer>
  );
}

export default App;