import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Grid, Avatar, Typography, Stack, Button, Chip, TextField, LinearProgress } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import FaceIcon from '@mui/icons-material/Face';
import IconButton from '@mui/material/IconButton';
import WarningIcon from '@mui/icons-material/Warning';
import { DataGrid } from '@mui/x-data-grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import api from '../utils/api';

const UserProfile = () => {
  const [avatarUrl, setAvatarUrl] = useState('');
  const [Name, setName] = useState('');
  const [Cell, setCell] = useState('');
  const [idUser, setId] = useState('');
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [token, setToken] = useState('');
  const [progress, setProgress] = useState(100);
  const [number, setNumber] = useState('');



  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('user'));
    if (userData && userData.url) {
      setAvatarUrl(userData.url);
      setName(userData.nombre);
      setCell(userData.cell);
      // setEmail(userData.correo);
      setId(userData.id);
    }
  }, []);



  const generateToken = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 10; i += 1) { // Corregido aquí
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  const handleCreateUser = async () => {
    // Genera un token aquí o asegúrate de que `token` es el valor correcto que deseas enviar
    const generatedToken = generateToken();

    if (!isEmailValid) {
      alert('Por favor, ingresa un correo electrónico válido.');
      return;
    }

    try {
      // Primero crea el usuario
      await api.post(`${process.env.REACT_APP_BACKEND_URL}/numRegistros`, {
        correo: email,
        numR: number 
      });

      const createUserResponse = await api.post(`${process.env.REACT_APP_BACKEND_URL}/crearuser`, {
        correo: email,
        token: generatedToken,
      });

      // Ahora prepara el cuerpo del correo electrónico
      const emailHtmlContent = `
      <!DOCTYPE html>
      <html lang="es">
      <head>
          <meta charset="UTF-8">
          <title>Confirmación de pago</title>
          <style>
              body {
                  font-family: Arial, sans-serif;
                  margin: 0;
                  padding: 0;
              }
              .email-container {
                  max-width: 600px;
                  margin: auto;
                  background-color: #ffffff;
                  padding: 20px;
              }
              .header-logo {
                  text-align: center;
                  margin-bottom: 20px;
              }
              .content-block {
                  text-align: center;
              }
              .reservation-info {
                  background-color: #f9f9f9;
                  padding: 15px;
                  border-radius: 8px;
              }
              .reservation-info h2 {
                  color: #333333;
              }
              .steps-section {
                  background-color: #f2f2f2;
                  padding: 10px;
                  border-radius: 8px;
                  margin-top: 20px;
              }
              .step {
                  margin-bottom: 10px;
              }
              .button {
                  display: inline-block;
                  padding: 10px 15px;
                  margin: 5px;
                  background-color: #4CAF50;
                  color: white;
                  border-radius: 5px;
                  text-decoration: none;
              }
              .copyable-text {
                  border: 1px solid #cccccc;
                  padding: 5px;
                  border-radius: 4px;
                  position: relative;
                  display: inline-block;
                  cursor: pointer;
              }
              .copy-icon {
                  display: inline-block;
                  width: 24px;
                  height: 24px;
                  background-image: url('path-to-copy-icon.png');
                  background-size: cover;
                  vertical-align: middle;
                  margin-left: 8px;
              }
              .copy-tooltip {
                  visibility: hidden;
                  width: 120px;
                  background-color: black;
                  color: #fff;
                  text-align: center;
                  border-radius: 6px;
                  padding: 5px 0;
                  position: absolute;
                  z-index: 1;
                  bottom: 125%;
                  left: 50%;
                  margin-left: -60px;
              }
              .copy-tooltip::after {
                  content: "";
                  position: absolute;
                  top: 100%;
                  left: 50%;
                  margin-left: -5px;
                  border-width: 5px;
                  border-style: solid;
                  border-color: black transparent transparent transparent;
              }
              .copyable-text:hover .copy-tooltip {
                  visibility: visible;
              }
          </style>
      </head>
      <body>
          <div class="email-container">
              <div class="header-logo">
                  <img src="https://businessadvocacy.com.mx/wp-content/uploads/2022/09/cropped-logo_business-removebg-preview-1-1-239x300.png" alt="Logo" width="100"/>
              </div>
              <div class="content-block">
                  <h2>Se ha realizado la confirmación de su pago</h2>
                  <img src="https://cdn-icons-png.flaticon.com/512/9443/9443565.png" style="width: 10%;"/>
                  <div class="reservation-info">
                      <h3>Pasos a seguir: </h3>
                      <div class="steps-section">
                          <p class="step">Paso 1: <a href="http://businessadvocacy.shop/signup" target="_blank" class="button">Click aquí para registrarte</a></p>
                          <p class="step">Paso 2: Utilizar el mismo correo para la creación de tu cuenta: <span class="copyable-text">${email}</span></p>
                          <p class="step">Paso 3: En la sección de token, ingresar: <span class="copyable-text">${generatedToken}</span></p>
                          <p class="step">Paso 4: Completar los campos faltantes.</p>
                          <p class="step">Paso 5: <a href="http://businessadvocacy.shop/login" target="_blank" class="button">Ir a la página de inicio de sesión</a> y usar el correo y contraseña creados anteriormente.</p>
                      </div>
                      <a href="path-to-your-help-link" class="button">Obtener ayuda</a>
                  </div>
              </div>
          </div>
          <script>
              function copyToClipboard(text, element) {
                  navigator.clipboard.writeText(text).then(function () {
                      let tooltip = element.querySelector('.copy-tooltip');
                      tooltip.innerText = 'Copiado!';
                      setTimeout(function () {
                          tooltip.innerText = 'Copiar';
                      }, 1500);
                  }).catch(function (error) {
                      console.error('No se pudo copiar el texto: ', error);
                  });
              }
          </script>
      </body>
      </html>      
      `;

      // Envía el correo electrónico
      await api.post(`${process.env.REACT_APP_BACKEND_URL}/send-email`, {
        to: email,
        subject: "Bienvenido a Business advocacy",
        html: emailHtmlContent
      });

      alert(`Usuario creado con éxito: ${createUserResponse.data.id}`);
    } catch (error) {
      console.error("Error al crear usuario o enviar correo electrónico:", error.response ? error.response.data : error.message);
      alert('Error al crear usuario o enviar correo electrónico');
    }
  };




  // Función para validar el correo electrónico
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Efecto para validar el correo electrónico cada vez que cambie
  useEffect(() => {
    setIsEmailValid(validateEmail(email));
  }, [email]);

  useEffect(() => {
    // Función para actualizar el token y reiniciar el progreso
    const updateTokenAndProgress = () => {
      setToken(generateToken());
      setProgress(100);
    };

    updateTokenAndProgress();
    const interval = setInterval(updateTokenAndProgress, 10000);

    // Función de limpieza para borrar el intervalo
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // Función para actualizar la barra de progreso
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 0) {
          clearInterval(timer);
          return 0;
        }
        return Math.max(oldProgress - 1, 0);
      });
    }, 100); // Actualiza el progreso cada 100 ms

    return () => clearInterval(timer);
  }, []);


  return (
    <>
      <Helmet>
        <title>Admin create user</title>
      </Helmet>
      <Container>
        <Stack direction="column" spacing={2} alignItems="center" mb={5}>
          <Typography variant="h4" gutterBottom>
            Crear usuario
          </Typography>
          <TextField
            label="Correo electrónico"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!isEmailValid && email.length > 0}
            helperText={!isEmailValid && email.length > 0 ? "Correo electrónico no válido" : " "}
          />
          <TextField
            label="Número"
            type="number"
            variant="outlined"
            value={number}
            onChange={(e) => setNumber(e.target.value)}
            inputProps={{ min: 1, max: 10 }}
          />


          <TextField
            label="Token"
            variant="outlined"
            value={token}
            InputProps={{
              readOnly: true,
            }}
          />
          <Button variant="contained" startIcon={<EditIcon />} size="small" onClick={handleCreateUser}>
            crear
          </Button>
        </Stack>
        <LinearProgress
          variant="determinate"
          value={progress}
          style={{
            height: '10px',
            borderRadius: '5px',
            backgroundColor: '#eee', // Color de fondo
          }}
          sx={{
            '& .MuiLinearProgress-bar': {
              backgroundColor: '#1976d2', // Color de la barra de progreso
            },
          }}
        />


      </Container>
    </>
  );


};

export default UserProfile;
